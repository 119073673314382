import React, {useState,useRef,useEffect} from "react"
import Quienes from "../components/quienes"
import Start from "../components/start"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
const Index = ({data}) => {
  const [start,setstart]=useState(false)
  return(
<> 
      <SEO title="Inicio"/>
        <Start setstart={setstart}/>
        <Quienes data={data}/>
        <Footer/>






</>
)}

export default Index
export const pageQuery = graphql`
query{
  allStrapiPublicaciones {
    edges {
      node {
        Descripcion
        Enlace
        Titulo
        Imagenes {
          url
        }
      }
    }
  }
}
`